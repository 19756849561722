/* == DROPDOWN LANG NOJS ================================================================================================================================= */
/* ================================================================================================================================================== */
/* ================================================================================================================================================== */

/*Variables*/
$greydark: #505154;
$greylight: #c7c9cb;
$greylighter: #eeefef;
$blue: #326aab;
$focuswaidark: #919191;

.ep-header {
  .dropdown-lang {
    .custom-select {
      display: none;
    }
  }

  .dropdown {
    height: 100%;
    .dropdown-lang {
      &.no-js {
        background-color: transparent;
        max-width: none;
        border: none;
        padding: 0;
        border-radius: 0;
        height: 100%;
        .wrapper-dropdown-lang {
          height: 100%;
          form {
            display: flex;
            margin-right: 10px;
            height: 100%;
            .select {
              margin-bottom: 0;
              margin-right: 2px;
              border: 0;
              background-color: transparent;
              height: 100%;
              label {
                width: auto;
              }
              select {
                width: 100%;
                height: 100%;
                border: 0;
                color: $greydark;
                border-color: $greylighter;
                font-size: 1.3em;
                padding: 11px 20px 11px 10px;
                @include transition(.3s);
                &:hover, &:focus {
                  background-color: $greylighter;
                  border-color: $greylighter;
                  color: $blue;
                }
                &:focus {
                  outline: 3px dotted $focuswaidark;
                  outline-offset: -3px;
                }
              }
              &:after {
                display: none;
              }
            }
            .btn {
              cursor: pointer;
              padding: 0 6px;
              background-color: $greydark;
              color: #fff;
              border: 1px solid $greydark;
              @include transition(.3s);

              &:hover, &:focus {
                background-color: $blue;
                border: 1px solid $blue;
                color: #fff;
              }
            }
          }
        }
      }
    }
  }

}